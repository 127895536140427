import { SwiperSlide } from "swiper/react";
import Swiper from "./Swiper";
import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import { PuffLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { EssentialsDataContext } from "../../contexts/EssentialsDataContext";
import { AnswersContext } from "../../contexts/AnswerContext";
import { ADVISER_LOGO_URL, EMAIL_TO} from "../../env";
import { CarouselStyled, LoaderContainer } from "../../styles/Screens/Recommendations";
import { Title, Text } from "../../styles/Texts";
import { CardSlider } from "./CardSlider";
import RatingStars from "../../components/RatingStars";
import { GenericButton } from "../../components/UI-KIT/GenereicButton";
import { T } from "../../styles/Theme";
import { getRecommendedServicesRequest, getResultPredictedRequest, sendHubspotData, sendNotificationEmail, sendRecommendationsEmail, updateAnswerWithServiceSelectedRequest, uploadAnswersRequest, uploadContactDataRequest, uploadEmailToAnswersRequest } from "../../utils/requests";
import { addContactToHubspot, parseHubspotData } from "../../utils/hubspot";

export const Recomendations = () => {
  const [
    , setFinalForm, ,
    setScreenNumber, , , , , ,
    setQuestionId, , setCurrentPage,
  ] = useContext(EssentialsDataContext);
  const [answers, setAnswers] = useContext(AnswersContext);

  const [data, setData] = useState<any[] | null>(null);
  const [length, setLength] = useState(1);

  const [finalForm] = useContext(EssentialsDataContext);

  const copyFinalForm: any[] = [];

  finalForm.forEach((el: any) => {
    const { id_question, answer } = el;
    copyFinalForm.push({ id_question, answer });
  });

  const post = {
    data: copyFinalForm,
  };

  useEffect(() => {
    getResultPredictedRequest(post)
      .then((res) => {
        return res.data.result;
      })
      .then(async (result) => {
        const arrItems: Array<any> = result;
        // setLength(result.length);
        const body = JSON.stringify({services_ids: arrItems });
        getRecommendedServicesRequest(body)
          .then(res => {
            setData(res.data);
            setLength(res.data?.length)

            if (answers.email) {
              const recommendationsData = {
                email_to: answers.email,
                services: res.data,
                questions: finalForm,
                adviser_logo: ADVISER_LOGO_URL
              }
              // const hubspotData: any = JSON.stringify(parseHubspotData(finalForm, answers, res.data))
              // addContactToHubspot(hubspotData).then(r => console.log("Hubspot", r.status))
              sendRecommendationsEmail(JSON.stringify(recommendationsData)).then(re => console.log("Advise!", re.status))
            }
            const results = res.data;
            return results;
          })
          .then(async (results) => {
            await submitAnswers(results);
          });
      })
      .then(() => {
        // if (!window.sessionStorage.getItem("tryadviser-adviseGenerated")) {
        //   window.sessionStorage.setItem("tryadviser-adviseGenerated", "true");
        // }
        // submitAnswers();
      });
  }, []);


  const submitAnswers = async (recommendations: Array<any>) => {
    const dataPost = {
      answers: {
        data: finalForm,
      },
      results: null,
    };

    uploadAnswersRequest(dataPost)
      .then(async response => {
        if (response.status === 201) {
          setAnswers({...answers, idAnswer: response.data.id});
          if (answers.email) {
            const emailData = {
              answer_id: response.data.id,
              email: answers.email,
            }
            const contactData = JSON.stringify({
              answer_id: response.data.id,
              full_name: answers.name,
              phone: answers.phone,
              remark: null
            });
            await uploadEmailToAnswersRequest(emailData).then(res => console.log("email", res.status))
            await uploadContactDataRequest(contactData).then(r_ => console.log("contact", r_.status))

            const hubspotData: any = JSON.stringify(parseHubspotData(finalForm, answers, recommendations || []))
            await sendHubspotData(hubspotData, response.data.id).then(r => console.log("Hubspot", r.status))
          }
        }
      });
  }

  const submitSelectedService = async (item: any) => {
    const dataPost = {
      results: {
        data: [item],
      }
    };
    updateAnswerWithServiceSelectedRequest(answers.idAnswer, dataPost)
      .then(response => {
        if (response.status === 200) {
          console.log("updated!");
        }
      }).then(() => {
        const body = {
          email_to: EMAIL_TO,
          link: (item.links.length > 0) ? item.links[0].link : "",
          image: (item.images.length > 0) ? item.images[0].image_url : "",
          product_name: item.name,
          product_description: "",
          email: (answers.email) ? answers.email : "",
          data: finalForm
        }
        sendNotificationEmail(body).then((res) => {console.log("EMAIL HAS BEEN SENT", res.status)})
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <CarouselStyled>
      {data ? (
        <div className="header">
          <TitleStyled>
            Te recomendamos {length} {length === 1 ? "producto" : "productos"}
          </TitleStyled>
          <TextStyled>Desliza para comparar las opciones</TextStyled>
        </div>
      ) : (
        <div className="headerSkeleton">
          <Title>
            <Skeleton />
          </Title>
          <TextStyled>
            <Skeleton />
          </TextStyled>
        </div>
      )}

      {data ? (
        <Swiper>
          {data.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <CardSlider
                  data={item}
                  onSelectService={() => submitSelectedService(item)}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <>
          <LoaderContainer>
            <PuffLoader color={T.primary} size={130} />
          </LoaderContainer>
        </>
      )}

      {data && (
        <>
          <RatingStars />
          <div className="buttonContainer">
            <GenericButton
              bg={T.primary}
              c="white"
              onClick={() => {
                setScreenNumber(0);
                setCurrentPage(0);
                setQuestionId(1);
                setFinalForm([]);
              }}
            >
              Volver a empezar
            </GenericButton>
          </div>
        </>
      )}
    </CarouselStyled>
  );
};

const TitleStyled = styled(Title)`
  margin: 30px 0 10px 0;
  font-weight: ${T.fontWeights.bold};
`;

const TextStyled = styled(Text)`
  padding: 0 20px 0px 20px;

  @media(max-width: 480px) {
    font-size: ${T.fontSizes.medium};
  }
`;
