import { createContext, useState} from 'react';
import { IAnswer } from '../Interfaces/IAnswer';

export const AnswersContext = createContext<any>(null);

interface IContext {
  children: React.ReactNode;
}

export const AnswersProvider = ({ children }: IContext) => {
  const [answers, setAnswers] = useState<IAnswer[]>([]);

  return (
    <AnswersContext.Provider value = {[answers, setAnswers]}>
      {children}
    </AnswersContext.Provider>
  );
}