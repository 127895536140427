import { useContext, useState, useEffect } from "react";
import {
  StartContainer,
  ImageSvgRes,
  ImageHeader,
} from "../../styles/Screens/Welcome";
import { Text, Title } from "../../styles/Texts";
import { T } from "../../styles/Theme";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GenericButton } from "../../components/UI-KIT/GenereicButton";
import { EssentialsDataContext } from "../../contexts/EssentialsDataContext";
import { DataInitialSetup } from "../../Interfaces/IInitialSetup";
import TextValues from "../../TextValues/index.json";
import styled from "styled-components";
import { getInitialSettings, uploadEventRequest } from "../../utils/requests";
import { ADVISER_IDENTIFIER } from "../../env";

export const Welcome = () => {
  const [, , screenNumber, setScreenNumber] = useContext(EssentialsDataContext);

  const handleStart = () => {
    setScreenNumber(screenNumber + 1);
    sendEvent();
  };

  const [settings, setSettings] = useState<DataInitialSetup | null>(null);

  useEffect(() => {
    getInitialSettings().then((res) => setSettings(res.data.data[0]));
  }, []);

  const sendEvent = () => {
    const code: string = `${ADVISER_IDENTIFIER}[qef23t45e@34t3.rinde]`;
    const body: any = JSON.stringify({
      data: {eventName: "next", tag: "P0", content: "Quiero comenzar"}
    });
    uploadEventRequest(body, code).then((res) => console.log(res));
  };

  return (
    <StartContainer>
      <ImageSvgRes>
        <ImageHeader />
      </ImageSvgRes>
      
      <TitleContainer>
        <Title>
          <TextDescriptionConatiner>
            {settings ? settings.title : <Skeleton />}
          </TextDescriptionConatiner>
          </Title>
          
      </TitleContainer>
      <DescriptionContainer>
        <Text>
          <TextTitleConatiner>
            {settings ? settings.description : <Skeleton count={4} />}
          </TextTitleConatiner>  
        </Text>
      </DescriptionContainer>

      <div className="buttonContainer">
        <GenericButton bg={T.primary} c="white" onClick={handleStart}>
          {TextValues.buttons.initialSetup}
        </GenericButton>
      </div>
    </StartContainer>
  );
};


const TitleContainer = styled.div`
  margin: 30px 50px 20px 50px;
  @media(max-width: 480px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const DescriptionContainer = styled.div`
  margin-right: 50px;
  margin-left: 50px;

  @media(max-width: 480px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

const TextTitleConatiner = styled.p`
  font-weight: 
  @media(max-width: 480px) {
    font-size: ${T.fontSizes.large};
  }
`;

const TextDescriptionConatiner = styled.p`
  @media(max-width: 480px) {
    font-weight: ${T.fontWeights.bold};
  }
`;
