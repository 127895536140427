// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";

import Arrow from "../../../assets/Icons/Swiper/SwiperArrow.svg";
import { SwiperStyled } from "../../../styles/Swiper";

interface ISwiper {
  children: React.ReactNode;
}

export default ({ children }: ISwiper) => {
  return (
    <SwiperStyled
      arrow={Arrow}
      slidesPerView={1}
      modules={[Pagination, Navigation]}
      navigation
      pagination={{ clickable: true }}
      onSlideChange={() => {}}
      onSwiper={() => {}}
    >
      {children}
    </SwiperStyled>
  );
};
