import { ChangeEvent, FC } from "react";
import styled from "styled-components"

interface Props {
    onClick: () => void;
    onKeyDown: (e: any) => void;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    value: string;
    history?: any;
}

export const Input: FC<Props> = ({ onClick,...props }) => {
  return (
    <InputStyled>
        {/* <svg
            viewBox="0 0 1024 1024"
            fill="currentColor"
            height="1em"
            width="1em"
            className="input__chat__icon"
        >
            <path d="M464 512a48 48 0 1096 0 48 48 0 10-96 0zm200 0a48 48 0 1096 0 48 48 0 10-96 0zm-400 0a48 48 0 1096 0 48 48 0 10-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 00-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 00-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 00112 714v152a46 46 0 0046 46h152.1A449.4 449.4 0 00510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 00142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z" />
        </svg> */}

        <input type="text" {...props} />
        
        <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            height="1em"
            width="1em"
            className="input__chat__arrow"
            onClick={onClick}
        >
            <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
        </svg>
    </InputStyled>
  )
}

const InputStyled = styled.div`
    display: flex;
    /* width: 100%; */
    height: 50px;
    margin-top: 20px;
    border-radius: 24px;
    position: relative;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
    
    & > input {
        border-radius: 38px;
        width: 100%;
        padding: 0 20px;
        border: 2px solid #2870EA;
        outline: none;
    }

    & > svg {
        width: 30px;
        height: 30px;
        position: absolute;
    }

    & > .input__chat__arrow {
        right: 15px;
        top: 15px;
        width: 20px;
        height: 20px;
        fill: #2870EA;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    & > .input__chat__icon {
        left: 15px;
        top: 5px;
        fill: gray;
        opacity: 0.7;
    }
`;