import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { CrossIcon } from "../../assets/Icons/CrossIcon";
import AdviserLink from "../UI-KIT/AdviserLink";
import Backdrop from "./Backdrop";

interface ModalProps {
  handleClose: () => void;
  modalOpen: boolean;
  children: React.ReactNode;
}

const dropIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const Modal = ({ handleClose, children }: ModalProps) => {
  return (
    <Backdrop>
      <AnimatePresence>
        <ModalStyled
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {/* <CrossIcon onClick={handleClose} /> */}
          <div style={{ height: "100%", overflowY: "auto", width: "100%" }}>
            {children}
            <AdviserLink />
          </div>
        </ModalStyled>
      </AnimatePresence>
    </Backdrop>
  );
};

export default Modal;

const ModalStyled = styled(motion.div)`
  position: relative;
  width: clamp(50%, 638px, 90%);
  max-width: 638px;
  /* min-height: 455px; */
  overflow-y: auto;
  margin: auto;
  max-height: calc(100vh - 100px);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;
