import styled from "styled-components";

const AdviserLink = () => {
  return (
    <AdviserLinkStyled>
      {"Usamos "}
      <a
        href="https://tryadviser.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Adviser
      </a>
    </AdviserLinkStyled>
  );
};

const AdviserLinkStyled = styled.div`
  /* position: absolute; */
  bottom: 2px;
  left: calc(50% - 32px);
  color: black;
  font-size: 10px;
  color: #a19f9f;
  padding-bottom: 3px;
  text-align: center;

  & > a {
    color: #a19f9f;
    text-decoration: none;

    &:hover {
      color: #313131;
      transition: 300ms;
    }
  }
`;

export default AdviserLink;