import { CheckWhiteIcon } from "../../../assets/Icons/CheckWhiteIcon";
import { AltBox, LabelStyled, RadioButtonStyled } from "../../../styles/GenericButton";

interface CheckBoxProps {
  text: any;
  id: any;
  name: string;
  tag: string;
  onClick: any;
  checked?: boolean;
}

export const CheckBox = ({
  text,
  id,
  name,
  tag,
  onClick,
  checked,
}: CheckBoxProps) => {

  return (
    <>
      <RadioButtonStyled
        type="checkbox"
        id={id ? id : ""}
        name={name}
        value={text ? text : ""}
        checked={checked ? checked : false}
        onChange={() => {}}
      />
      <LabelStyled htmlFor={id} whileTap={{ scale: 0.98 }} onClick={onClick}>
        <AltBox>{checked && <CheckWhiteIcon/> }</AltBox>
        {text}
      </LabelStyled>
    </>
  );
};
