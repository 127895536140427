import styled from "styled-components";
import { T } from "../Theme";

export const CircleStyled = styled.div`
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${T.grayStepBar};
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: ${T.fontWeights.bold};
    font-size: ${T.fontSizes.small};
    text-align: center;
    color: black;
  }

  .active {
    background-color: ${T.secondary};
  }

  @media (min-width: 480px) {
    .circle {
      width: 40px;
      height: 40px;
  }
`;

export const ProgressStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  align-items: center;
  justify-items: center;
  width: 100%;
  max-width: 520px;
  margin: auto;

  .circleContainer {
    display: flex;
    align-items: center;
    z-index: 10000;
  }

  .line {
    position: absolute;
    width: 85%;
    max-width: 300px;
    z-index: 1000;
    height: 5px;
    background-color: ${T.grayStepBar};
  }

  @media (min-width: 480px) {
    .line {
      position: absolute;
      width: 90%;
      max-width: 400px;
  }
`;

export const TextStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  justify-content: space-between;
  color: #666666;
  max-width: 520px;
  margin: auto;
  text-align: center;

  p {
    font-size: 7px;
    padding: 4px;
  }

  .textActive {
    color: black;
    font-weight: ${T.fontWeights.bold};
  }

  @media (min-width: 480px) {
    p {
      font-size: ${T.fontSizes.small};
      padding: 12px 5px;
    }
  }
`;
