interface StarProps {
  fill?: string;
}

export const Stars: React.FC<StarProps> = ({ fill }: StarProps) => {
  return (
    <svg
      width="19"
      height="18"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.608 6.119l-5.455-.793L9.715.382a.69.69 0 00-1.233 0L6.043 5.326l-5.455.793a.686.686 0 00-.38 1.173l3.947 3.848-.933 5.433a.686.686 0 00.997.724l4.88-2.565 4.878 2.565a.686.686 0 00.997-.724l-.932-5.433 3.946-3.848a.686.686 0 00.2-.393.685.685 0 00-.58-.78z"
        fill={fill}
      />
    </svg>
  );
};
