import { ReactElement } from "react";
import { CheckWhiteIcon } from "../../../assets/Icons/CheckWhiteIcon";
import { RadioButtonStyled, LabelStyled, AltBox } from "../../../styles/GenericButton";

interface AlternativeButtonProps {
  text: string;
  id: any;
  name: string;
  tag: string;
  onClick: any;
  checked: boolean;
  icon?: ReactElement;
}

const AlternativeButton = ({
  text,
  id,
  name,
  tag,
  onClick,
  checked,
  icon,
}: AlternativeButtonProps) => {

  return (
    <div>
      <RadioButtonStyled
        type="radio"
        id={id}
        name={name}
        value={text}
        checked={checked}
        onChange={() => {}}
      />
      <LabelStyled
        htmlFor={id}
        whileTap={{ scale: 0.98 }}
        onClick={onClick}
        gr={icon}
      >
        <AltBox>{checked && <CheckWhiteIcon />}</AltBox>
          {icon}
          {text}
      </LabelStyled>
    </div>
  );
};

export default AlternativeButton;
