export const CheckIconSmall = () => {
  return (

  <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
    <g id="surface1">
      <path 
        d="M 5.511719 10.414062 L 2.246094 7.144531 L 2.871094 6.519531 L 5.511719 9.160156 L 11.113281 3.558594 L 11.738281 4.183594 Z M 5.511719 10.414062 "
        stroke="#FFFFFF"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
  );
};
