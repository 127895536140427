import axios from "axios"
import { HUBSPOT_API_URL } from "../../env"

const getQuestionKey = (questionId: number) => {
  
  switch (questionId) {
    case 3: return "pais_rg"
    case 4:
    case 5:
    case 6:
    case 7:
    case 8: return "problematica_adviser"
    case 9:
    case 10:
    case 11:
    case 12:
    case 13: return "erp"
    case 14: return "cargo"
    case 15: return "industry"
    case 16: return "numemployees"
    case 17: return "cantidad_de_usuarios_rendidores"
    default: return ""
  }
}

const parsePais = (text: string) => {
  // key: pais_rg
  switch (text.trim()) {
    case "México": return "Mexico"
    default: return text.trim()
  }
}

const parseProblematica = (text: string) => {
  // key: problematica_adviser

  switch (text.trim()) {
    case "Gestión de fondos y cajas chicas": return "Gestión de fondos y caja chica"
    case "Gestión de fondos y cajas menores": return "Gestión de fondos y caja chica"
    case "Gestión de anticipos y cajas chicas": return "Gestión de fondos y caja chica"
    default: return text.trim()
  }
}

const parseRol = (text: string) => {
  // key: cargo
  switch (text.trim()) {
    case "CEO": return "Dueño"
    case "Gerente de Adm y Finanzas": return "Gerente"
    case "Jefe de Contabilidad o Tesorería": return "Jefe"
    case "Contador": return "Administrativo"
    // case "Analista": return "Analista"
    case "Estudiante": return "Otro"
    case "Otro": return "Otro"
    default: return ""
  }
}

const parseIndustry = (text: string) => {
  // key: industry
  switch (text.trim()) {
    case "Construcción / Inmobiliaria": return "Construccion"
    case "Ingeniería y/o proyectos": return "Consultoria y servicios profesionales"
    case "Farmacéuticas / Salud": return "Salud"
    case "Retail": return "Retail"
    case "Transporte / Logística": return "Transporte almacenamiento y logistica"
    case "Agrícolas / Acuícolas": return "Agricultura ganaderia silvicultura y pesca"
    case "Alimentación": return "Alimentacion"
    case "Otro": return "Otro"
    default: return ""
  }
}

const parseNumberOfEmployee = (text: string) => {
  // key: numemployees
  switch (text.trim()) {
    case "1 a 50": return "25-50"
    case "51 a 100": return "50-100"
    case "101 a 150": return "100-500"
    case "151 a 250": return "100-500"
    case "251 a 500": return "100-500"
    case "501 o más": return "500-1000"
    default: return ""
  }
}

const parseUsersRendidores = (text: string) => {
  // key: cantidad_de_usuarios_rendidores
  switch (text.trim()) {
    case "1 a 5": return "Tramo 0: 1 a 5"
    case "6 a 15": return "Tramo 1: 6 a 15"
    case "16 a 24": return "Tramo 2: 16 a 24"
    case "25 a 49": return "Tramo 3: 25 a 49"
    case "50 a 69": return "Tramo 4: 50 a 69"
    case "70 a 149": return "Tramo 5: 70 a 149"
    case "150 a 299": return "Tramo 6: 150 a 299"
    case "300 a 499": return "Tramo 7: 300 a 499"
    case "500 a 799": return "Tramo 8: 500 a 799"
    case "800 o más": return "Tramo 9: 800 a 1.199"
    default: return ""
  }
}

const parseERP = (text: string) => {
  // key: erp
  switch (text.trim()) {
    // País 1
    case "SAP": return "SAP BO"
    case "Oracle": return "Oracle"
    case "DeFontana": return "Defontana"
    case "Softland": return "Softland"
    case "Manager": return "Manager"

    // País 2
    case "Siesa": return "SIESA"

    // País 3
    case "Infor": return "Infor ERP"
    case "Sage": return "Sage 200"

    case "Otro": return "Otro"
    default: return text.trim()
  }
}

const parseServices = (serviceName: string) => {
  // key: plan_rindegastos
  switch (serviceName.trim()) {
    case "Plan Starter": return "RG-STARTER-REG"
    case "Plan Business": return "RG-BUSINESS-REG"
    case "Plan Corporate": return "RG-CORPORATE-REG"
    case "Plan Enterprise": return "RG-ENTEPRISE-REG"
    default: return ""
  }

}

const parseHubspotData = (answersData: Array<any>, contactData: any, services: Array<any>) => {
  const hubspotData: any = {
    properties: {
      email: contactData.email,
      firstname: contactData.name,
      phone: contactData.phone,
    }
  }

  if (services.length > 0 && services[0].type?.name  === "service") {
    hubspotData.properties['plan_rindegastos'] = parseServices(services[0]?.name?.trim() || "")
  }

  answersData.forEach((answer: any) => {
    const questionKey = getQuestionKey(answer.id_question)
    if (questionKey) {
      hubspotData.properties[questionKey] = (typeof(answer.alternative_text) === 'string') ? answer.alternative_text.trim() : answer.alternative_text.join(';')
    }
  })

  if (hubspotData.properties['cargo']) {
    hubspotData.properties['cargo'] = parseRol(hubspotData.properties['cargo'])
  }

  if (hubspotData.properties['industry']) {
    hubspotData.properties['industry'] = parseIndustry(hubspotData.properties['industry'])
  }

  if (hubspotData.properties['numemployees']) {
    hubspotData.properties['numemployees'] = parseNumberOfEmployee(hubspotData.properties['numemployees'])
  }

  if (hubspotData.properties['cantidad_de_usuarios_rendidores']) {
    hubspotData.properties['cantidad_de_usuarios_rendidores'] = parseUsersRendidores(hubspotData.properties['cantidad_de_usuarios_rendidores'])
  }

  if (hubspotData.properties['erp']) {
    hubspotData.properties['erp'] = parseERP(hubspotData.properties['erp'])
  }

  if (hubspotData.properties['problematica_adviser']) {
    let currentProblematica = hubspotData.properties['problematica_adviser']

    // Split by ;
    if (currentProblematica.includes(';')) {
      const problematicas = currentProblematica.split(';').map((item: string) => parseProblematica(item))
      hubspotData.properties['problematica_adviser'] = problematicas.join(';')
    } else {
      hubspotData.properties['problematica_adviser'] = parseProblematica(currentProblematica)
    }
    
  }

  if (hubspotData.properties['pais_rg']) {
    hubspotData.properties['pais_rg'] = parsePais(hubspotData.properties['pais_rg'])
  }

  return hubspotData
}


const addContactToHubspot = async (data: any) => { 

  const config = {
    headers: {
      'Content-Type': 'application/json',
    }
  }

  return await axios.post(`${HUBSPOT_API_URL}`, data, config)
};

export {
  parseHubspotData,
  addContactToHubspot
}