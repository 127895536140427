import styled from "styled-components";
import welcomeImage from "../../../assets/Images/Welcome/WelcomeImage.svg";
import welcomeImageMobile from "../../../assets/Images/Welcome/WelcomeImageMobile.svg";

export const StartContainer = styled.div`
  .buttonContainer {
    margin: 10px auto 20px;
    max-width: 180px;
  }
`;

export const ImageHeader = styled.div`
  background-image: url(${welcomeImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 10%;
  width: 100%;
  height: 267px;

  @media (max-width: 480px) {
    background-image: url(${welcomeImageMobile});
  }
`;

export const ImageSvgRes = styled.div`
  & > svg {
    width: 100%;
    height: 266px;
  }

  .fullImage {
    display: none;
  }

  @media (min-width: 480px) {
    .fullImage {
      display: block;
    }

    & > svg {
      width: 100%;
      height: 266px;
    }

    .cropImage {
      display: none;
    }
  }
`;
