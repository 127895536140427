import { GenericButton } from "../../../components/UI-KIT/GenereicButton";
import { T } from "../../../styles/Theme";
import { CardContent, CardStyled, FooterContainer, MultilineText, PrimaryCard, SecondaryCard} from "../../../styles/Screens/Recommendations";
import TextValues from "../../../TextValues/index.json";
import styled from "styled-components";
import { CheckIconSmall } from "../../../assets/Icons/CheckIconSmall";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { EssentialsDataContext } from "../../../contexts/EssentialsDataContext";
import TypingEffect from "../../../components/TypingEffect";
// import { useSwiper, useSwiperSlide } from "swiper/react";
import { ChatIcon } from "../../../assets/Icons/ChatIcon";
import "react-loading-skeleton/dist/skeleton.css";
import ThreeDots from "../../../components/ThreeDots";
import { parseQuestionsAndAnswers } from "../../../utils/openai";
import { getChatPredictionRequest, sendPrompt, updateChatRequest, uploadChatRequest } from "../../../utils/requests";

import * as C from "../../../components/UI-KIT/ChatUI"
import { ADVISER_IDENTIFIER } from "../../../env";
import { CompanyLogo } from "../../../assets/Images/Logo";
import { AnswersContext } from "../../../contexts/AnswerContext";

interface ICardSlider {
  data: any;
  onSelectService: any;
}

export const CardSlider = ({ data, onSelectService }: ICardSlider) => {
  let description = data.description ? data.description.split("--") : [];
  description = description.filter((el: any) => el !== "");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const [finalForm] = useContext(EssentialsDataContext);
  const [chat, setChat] = useState("");

  const [inputValue, setInputValue] = useState("")
  const [history, setHistory] = useState([])
  const [chatLoading, setChatLoading] = useState<boolean>(false)

  const [temporaryHistory, setTemporaryHistory] = useState<string>("")
  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(1)

  const [answers, setAnswers] = useContext(AnswersContext);

  useEffect(() => {
    if(messagesEndRef.current) messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    // console.log(currentMessageIndex)
    if (history.length > 0) {
      if (answers.idchat) {
        const bodyModifyChat = JSON.stringify({
          chat: {data: captureDatachat(history)}
        });
        updateChatRequest(bodyModifyChat, answers.idchat).then((response) => {
          console.log(response.status)
        });
      } else {
        const bodyInitialChat = JSON.stringify({
          answer_id: answers.idAnswer,
          chat: {data: captureDatachat(history)}
        });
        uploadChatRequest(bodyInitialChat).then((response) => {
          setAnswers({...answers, idchat: response.data.id})
        });
      }
    }
  }, [history])

  const sendChatMessage = async () => {
    setCurrentMessageIndex( prev => prev + 1 )
    setTemporaryHistory( inputValue )

    if(messagesEndRef.current) messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    
    setChatLoading( true )
    setInputValue( "" )

    const { data } = await sendPrompt({ "message": inputValue, history, "first_message": chat})
    
    setHistory( data.history )
    setTemporaryHistory( "" )
    setChatLoading( false )
  }

  const handleKeyDown = (event: any) => {
    if( event.key === "Enter") {
        event.preventDefault();
        sendChatMessage()
    }
  }

  const captureDatachat = (data: Array<any>): Array<any> => {
    let dataChat: Array<any> = []
    if (data.length > 0) {
      dataChat = data.filter((item: any) => !(item.role === "system")).map((obj: any) => {
        if (obj.role === "assistant") {
          return {"role": "system", "content": obj.content}
        }
        return {"role": "user", "content": obj.content}
      })
    }
    return dataChat
  }

  // const swiper = useSwiperSlide();

  useEffect(() => {
    const bodyTest = JSON.stringify({
      data: parseQuestionsAndAnswers(finalForm),
      product: data.name,
      type: 1,
      number_of_characters: 280,
      emoji: true,
    });
    getChatPredictionRequest(bodyTest).then((response) => {
      setChat(response.data.result);
    });
    // setTimeout(() => {
    //   setChat(data?.remark)
    // }, 3000);
  }, []);


  if (data?.type?.name !== "service") {
    return (
      <>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            margin: '20px 0 30px -10px',
          }}
        >
          <CompanyLogo />
        </span>
        <CardStyled>
        <FooterContainer>
          <MultilineText>
            {data?.description}
          </MultilineText>
          <div style={{display: 'flex', justifyContent: 'center', 'marginTop': '20px'}}>
            <div>
          {(data?.links[0]?.link) && (
          <ButtonStandard
                onClick={() => {
                  window.open(data?.links[0].link);
                  onSelectService();
                }}
              >
                <span style={{'fontSize': '16px'}}>
                  Contáctanos
                </span>
              </ButtonStandard>)
              }
              </div>
            </div>
        </FooterContainer>
      </CardStyled>
    </>
    )
  }

  return (
    <CardStyled>
      <PrimaryCard>
        <p style={{ fontSize: `${T.fontSizes.small}`, paddingBottom: "10px" }}>
          {TextValues.adviserTitle}
        </p>
        <p className="titleService">{data.name}</p>
        <ImageContainer>
          <img src={data?.images[0].image_url} alt="imagen" />
        </ImageContainer>
      </PrimaryCard>
      <CardContent>
        <SecondaryCard>
          <div className="match">Match: 100%</div>
          <ul>
            {description.map((value: any, i: number) => (
              <li className="down_arrow" key={i}>
                <span className="icon">
                  <CheckIconSmall />
                </span>
                <p>{value}</p>
              </li>
            ))}
          </ul>
        </SecondaryCard>
        <div className="buttonContainer">
          <GenericButton
            bg={T.secondary}
            c="white"
            onClick={() => {
              window.open(data?.links[0].link);
              onSelectService();
            }}
          >
            <span style={{ fontSize: "16px" }}>
              {TextValues.buttons.goToService}
            </span>
          </GenericButton>
        </div>
      </CardContent>

      {/* CHAT */}
      <FooterContainer>
        {chat !== "" ? (
          <div className="footerContent">

            <C.Message role="assistant" index={1}>
              <ChatIcon />
              <TypingEffect text={chat} />
            </C.Message>

            {
              history.map((item: { role: "assistant" | "user", content: string }, index) => {
                if (index === 0) return null;

                return (
                    <>
                      {
                        item.role === "user" ?
                        <C.Message role={ item.role } >
                          { item.content }
                        </C.Message>
                        :
                        <C.Message role="assistant" index={
                          index === 1 ? 1 :
                          index === 2 ? 2 :
                          index === 4 ? 3 :
                          index === 6 ? 4 :
                          index === 8 ? 5 : index
                        }>                        
                          <ChatIcon />
                          <TypingEffect text={item.content} />
                        </C.Message>
                      }
                      <div ref={messagesEndRef} />
                    </>
                )
              })
            }

            { temporaryHistory !== "" && <C.Message role={ "user" }> { temporaryHistory } </C.Message> }
            { chatLoading && <ThreeDots />}

            {
              currentMessageIndex < 5 &&
              <C.Input 
                placeholder="Escribe tu mensaje..."
                onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                onClick={ sendChatMessage }
                value={ inputValue }
                onKeyDown={handleKeyDown}
              />
            }
            

          </div>
        ) : (
          <>
            <div className="footerContent">
              <C.Message role="assistant" index={1}>
                <ChatIcon />
                <ThreeDots />
              </C.Message>
            </div>
          </>
        )}
      </FooterContainer>


    </CardStyled>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: left;
  // pagdding with good space
  padding: 20px 0 20px 0;

  img {
    width: 200px;
    height: 200px;
  }
`;

export const ButtonStandard = styled.button`
  background-color: ${T.secondary};
  border: 2px solid #FFFFFF;
  border-radius: ${T.borderRadius.button};
  color: white;
  font-size: ${T.fontSizes.medium};
  font-weight: ${T.fontWeights.bold};
  width: 180px;
  height: 46px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #FFFFFF;
    color: ${T.secondary};
    border: 2px solid ${T.secondary};

    .shoppingCartIcon {
      fill: ${T.secondary};
    }
  }

  &:active {
    color: white;
    transform: scale(0.95);
  }

  &:disabled {
    background-color: ${T.gray};
    border: 1px solid ${T.gray};
    pointer-events: none;
    color: white;
  }

  .shoppingCartIcon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    fill: #FFFFFF;
    margin: 0 8px -2px 0;
  }
`;
