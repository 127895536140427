import { motion } from "framer-motion";
import { FC, ReactNode } from "react"
import styled from "styled-components"

interface Props {
    role: "assistant" | "user";
    children: ReactNode;
    history?: any;
    index?: number
}

export const Message: FC<Props> = ({ role, children, index }) => {
  return (
    <Container type={role}>
        <MessageHeader 
            type={role} 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            { children }
        </MessageHeader>


        {
            role === "assistant" &&
            <>
                <hr />
                <MessageFooter>
                    { index } de 5 <span className="__circle"></span>
                </MessageFooter>
            </>
        }
    </Container>
  )
}

const MessageHeader = styled(motion.div)<{ type: string }>`
    padding: 10px 16px;
    display: ${ ({ type }) => type === "user" ? "block" : "grid" };
    grid-template-columns: 40px 1fr;
`;

const MessageFooter = styled.div`
    padding: 10px 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;

    & > .__circle {
        margin-left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: green;
    }
`;

const Container = styled.div<{ type: string }>`
    background: ${ ({ type }) => type === "user" ? "linear-gradient(90deg, #2870EA 10.79%, #1B4AEF 87.08%)" : "rgba(255, 255, 255, 0.6)" };
    border-radius: 12px;
    color: ${ ({ type }) => type === "user" ? "white" : "inherit" };
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
    max-width: 450px;
    
    place-self: ${ ({ type }) => type === "user" ? "flex-end" : "inherit" };
    
    & > hr {
        opacity: 0.4;
    }    
`;