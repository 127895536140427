import styled from "styled-components";
import { T } from "../../Theme";
import { motion } from "framer-motion";

export const CarouselStyled = styled.div`
  width: 100%;

  .header {
    margin-top: 20px;
  }

  .headerSkeleton {
    margin: 40px 30px 0 30px;
  }

  .buttonContainer {
    max-width: 200px;
    margin: 15px auto 30px auto;
  }

  @media (min-width: 640px) {
    .header {
      margin: 0 30px;
    }
  }
`;

export const InfoIconStyled = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
  cursor: pointer;

  @media (min-width: 640px) {
    display: none;
  }
`;

export const PrimaryCard = styled.div`
  position: relative;
  max-width: 260px;
  width: 100%;
  margin: auto;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  padding: 20px 10px 20px 20px;

  & > ul {
    list-style: none;
    margin-top: 2px;

    li {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 10px;
      align-items: center;
      margin: 8px 0;

      p {
        font-size: 14px;
      }

      .icon {
        margin-right: 5px;
        background-color: ${T.primary};
        border-radius: 50%;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > .titleService {
    font-size: ${T.fontSizes.medium};
    font-weight: 600;
  }
`;

export const CardStyled = styled.div`
  margin: 0 20px 20px 20px;
  border-radius: 12px;
  padding: 15px;
  /* max-height: 445px; */

  @media (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin: 20px 50px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
  }
`;

export const CardContent = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  .match {
    padding: 2px 6px;
    background: #42e8b4;
    border-radius: 4px;
    font-size: 20px;
    color: #191919;
    font-weight: 700;
    text-align: center;
    width: 137px;
    margin: 10px auto;
  }

  .description2 {
    display: none;
  }

  .description2 > ul {
    padding-left: 4px;
    margin: 15px 0 20px 0;

    li {
      font-size: 14px;
      margin: 8px 0;
    }
  }

  .buttonContainer {
    margin-top: 20px;
    max-width: 180px;
    width: 100%;
  }

  @media (min-width: 640px) {
    display: block;
    .match {
      margin: 10px 0;
    }

    .description2 {
      display: block;
    }

    .buttonContainer {
      margin-top: 0;
    }
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding-bottom: 8px;
  font-size: ${T.fontSizes.medium};
  line-height: 24px;
  text-align: justify;
  height: auto;
  grid-column-start: 1;
  grid-column-end: -1;

  .footerContent {
    display: grid;
    /* grid-template-columns: 30px 1fr; */
    grid-gap: 10px;
    /* padding: 20px; */
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 330px;
`;

export const SecondaryCard = styled.div`

@media (max-width: 340px) {
  display: none;
}

position: relative;
  max-width: 260px;
  width: 100%;
  margin: auto;
  padding: 40px 10px 20px 20px;
  

  .match {
    padding: 5px;
    margin-bottom: 30px;
    background: ${T.primary};
    color: #FFFFFF;
    font-size: 15px;
    border-radius: 0px;
    align-items: center;
    justify-content: center;
    width: 137px;
    height: 28px;
  }

  & > ul {
    list-style: none;
    margin-top: 2px;

    li {
      display: grid;
      grid-template-columns: 30px 1fr;
      grid-gap: 0px;
      align-items: center;
      margin: 8px 0;

      p {
        font-size: ${T.fontSizes.medium};
      }

      .icon {
        margin-right: 5px;
        background-color: ${T.primary};
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  & > .titleService {
    font-size: ${T.fontSizes.medium};
    font-weight: 600;
  }
`;

export const MultilineText = styled(motion.p)`
  margin: 0;
  padding: 14px 0;
  color: gray;
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
  line-height: 1.4;
`;