import styled from "styled-components";
import { motion } from "framer-motion";
import { T } from "../Theme";

interface backgroundColor {
  bc: string;
  c: string;
}

export const ButtonStyled = styled.button`
  background-color: ${({ bc }: backgroundColor) => bc};
  border: 2px solid;
    ${({ bc }: backgroundColor) => (bc === "transparent" ? T.gray : bc)};
  border-radius: ${T.borderRadius.button};
  color: ${({ c }) => c || "white"};
  font-size: ${T.fontSizes.medium};
  font-weight: ${T.fontWeights.bold};
  width: 100%;
  height: 46px;
  cursor: pointer;
  transition: 0.3s;

  &:active {
    color: white;
  }

  &:hover {
    background-color: ${({ c, bc }) =>
      bc !== "transparent" ? "transparent" : c};
    color: ${({ c, bc }) => (c !== "white" ? "white" : bc)};
    transition: 0.3s;
  }

  &:disabled {
    background-color: ${T.gray};
    border: 1px solid ${T.gray};
    pointer-events: none;
    color: white;
  }
`;

export const RadioButtonStyled = styled(motion.input)`
  opacity: 0;
  position: fixed;
  width: 0;

  &:checked + label {
    & > div {
      background-color: ${T.primary};
    }
  }
`;

interface ILabelStyled {
  gr?: any;
}

export const LabelStyled = styled(motion.label)`
  display: grid;
  /* grid-template-columns: 35px 1fr; */
  grid-template-columns: ${(props: ILabelStyled) => props.gr ? "35px 45px 1fr" : "35px 1fr"};
  place-items: center start;
  padding: 4px 20px;
  margin: 0 auto;
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  min-height: 40px;
  cursor: pointer;
  font-size: 14px;
  color: #191919;

  @media (min-width: 480px) {
    margin: 5px auto;
  }
`;

export const AltBox = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: white;
  margin-right: 10px;
  border: 2px solid ${T.primary};
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  align-items: center;

  svg {
    color: white;
  }
`;
