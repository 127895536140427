export const CheckWhiteIcon = () => {
  return (
    <svg width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.43 5.611a.535.535 0 0 1 0-.732l.71-.733c.192-.2.515-.2.708 0L5.842 8.31a.342.342 0 0 0 .483 0L14.152.15a.496.496 0 0 1 .709 0l.708.733c.193.2.193.533 0 .733L6.454 11.14a.446.446 0 0 1-.709 0L.431 5.612Z"
        fill="#fff"
      />
    </svg>
  );
};
