import { useContext } from "react";
import Modal from "./components/Modal";
import StartButton from "./components/StartButton";
import { ModalContext } from "./contexts/ModalContext";
import Screens from "./Screens";

function App() {
  // const [modalOpen, , , close] = useContext(ModalContext);
  const [modalOpen, ,open , close] = useContext(ModalContext);

  return (
    <>
      {/* <StartButton modalOpen={modalOpen} open={open} close={close} /> */}
      {!modalOpen && (
        <Modal modalOpen={modalOpen} handleClose={close}>
          <Screens />
        </Modal>
      )}
    </>
  );
}

export default App;
