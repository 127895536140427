import { ProgressStyled, TextStyled } from "../../styles/StageProgressBar";
import { Circle } from "./Circle";
import { useState, useEffect } from "react";
import { StagesData } from "../../Interfaces/IStages";
import { getStagesRequest } from "../../utils/requests";

interface IStageProgressBarProps {
  step: number;
  currentStep: number;
}

export const StageProgressBar = ({ currentStep }: IStageProgressBarProps) => {
  const [stage, setStage] = useState<StagesData[] | null>(null);

  useEffect(() => {
    getStagesRequest().then((res) => setStage(res.data.data));
  }, []);

  return (
    <>
      <ProgressStyled>
        {stage?.map((item, i) => {
          return (
            <div key={`${i}+${item.name}`} className="circleContainer">
              <Circle
                stage={item.id}
                currentStage={currentStep}
                text={item.name}
                index={i + 1}
              />
            </div>
          );
        })}
        <div className="line"></div>
      </ProgressStyled>
      <TextStyled>
        {stage?.map((item, i) => {
          return (
            <p
              key={`${i}+${item.name}`}
              className={currentStep === item.id ? "textActive" : ""}
            >
              {item.name}
            </p>
          );
        })}
      </TextStyled>
    </>
  );
};
