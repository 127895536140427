import React, { useContext } from "react";
import styled from "styled-components";
import { GenericButton } from "../UI-KIT/GenereicButton";
import { T } from "../../styles/Theme";
import { AnswersContext } from "../../contexts/AnswerContext";

interface CommentBoxProps {
  placeholder?: string;
  onSubmitComment: () => void;
}

const CommentBox: React.FC<CommentBoxProps> = ({placeholder, onSubmitComment}) => {
  const [comment, setComment] = React.useState("");
  const [isCommentSent, setIsCommentSent] = React.useState(false);
  const [answers, setAnswers] = useContext(AnswersContext);

  const handleSubmit = () => {
    onSubmitComment();
    setIsCommentSent(true);
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    setAnswers({...answers, comment: e.target.value});
  }

  if (isCommentSent) {
    return (
      <FinalMessageContainer>
        <p>¡Gracias por ayudarnos a mejorar!</p>
      </FinalMessageContainer>
    );
  }

  return (
    <>
      <StyledTextArea
        placeholder={placeholder}
        value={comment}
        onChange={(e) => handleCommentChange(e)}
        rows={4}
      />
      <GenericButton
        bg={T.secondary}
        c="white"
        onClick={handleSubmit}
        disabled={(comment.length > 10) ? false : true}
      >
        Enviar comentario
      </GenericButton>
    </>
  );
};


const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 16px;
  resize: none;
  margin-bottom: 10px;
  box-shadow: 0px 0px 3px #ccc;
`;

const FinalMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  p {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
`;

export default CommentBox;