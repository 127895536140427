import { createContext, useState, useEffect } from "react";

export const EssentialsDataContext = createContext<any>(null);

interface IContext {
  children: React.ReactNode;
}

export const EssentialsDataProvider = ({ children }: IContext) => {
  const [questionId, setQuestionId] = useState<number | null>(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [finalForm, setFinalForm] = useState([]);
  const [screenNumber, setScreenNumber] = useState(0);
  const [previousScreens, setPreviousScreens] = useState([]);

  useEffect(() => {}, [finalForm]);

  return (
    <EssentialsDataContext.Provider
      value={[
        finalForm,
        setFinalForm,
        screenNumber,
        setScreenNumber,
        previousScreens,
        setPreviousScreens,
        ,
        ,
        questionId,
        setQuestionId,
        currentPage,
        setCurrentPage,
      ]}
    >
      {children}
    </EssentialsDataContext.Provider>
  );
};
