import styled from "styled-components";
import { Swiper } from "swiper/react";

interface ArrowProps {
  arrow: any;
}

export const SwiperStyled = styled(Swiper)`
  height: 100%;

  .swiper-pagination {
    bottom: 15px;
  }

  .swiper-pagination-bullet-active {
    background-color: black;
    height: 10px;
    width: 10px;
  }

  .swiper-button-next {
    background-image: ${(props: ArrowProps) => `url(${props.arrow})`};
    background-repeat: no-repeat;
    background-position: center;
    right: 5px;
    top: 40%;
    transform: scale(0.9);
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev {
    background-image: ${(props: ArrowProps) => `url(${props.arrow})`};
    background-repeat: no-repeat;
    background-position: center;
    left: 5px;
    top: 40%;
    transform: rotate(180deg) scale(0.9);
  }

  .swiper-button-prev::after {
    display: none;
  }

  @media (min-width: 640px) {
    padding-bottom: 30px;

    .swiper-button-next {
      right: 12px;
    }

    .swiper-button-prev {
      left: 12px;
    }
  }
`;
