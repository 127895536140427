import styled, { keyframes } from 'styled-components';

const typingFade = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;

const TypingContainer = styled.div`
  width: 5em;
  height: 2em;
  position: relative;
  padding: 10px;
  margin-left: 5px;
  background: #e6e6e6;
  border-radius: 20px;
`;

const TypingDot = styled.div`
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: ${typingFade} 1s infinite;
`;

const ThreeDots = () => {
  return (
    <TypingContainer>
      <TypingDot />
      <TypingDot style={{animationDelay: '0.2s'}} />
      <TypingDot style={{animationDelay: '0.4s'}} />
    </TypingContainer>
  );
}

export default ThreeDots;
