import {ReactNode} from "react";
import { ModalProvider } from "./ModalContext";
import { AnswersProvider } from "./AnswerContext";
import { EssentialsDataProvider } from "./EssentialsDataContext";

interface IContextLayout {
  children: ReactNode;
}

export const ContextLayout = ({ children }: IContextLayout) => {
  return (
    <ModalProvider>
      <EssentialsDataProvider>
        <AnswersProvider>
          {children}
        </AnswersProvider>
      </EssentialsDataProvider>
    </ModalProvider>
  );
};
