const getTagIdentifier = (questionID: number) => {
  return `P${questionID}`;
};

export const generateDataEvent = (questionID: number, questionText: string) => {
  return {
    tag: getTagIdentifier(questionID),
    content: questionText.trim(),
    eventName: "next"
  };
};