import { useEffect, useState } from "react";

interface TypingEffectProps {
  text: string;
}

const TypingEffect = ({text}: TypingEffectProps) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    if (!text.length) return;

    const randomInterval = Math.floor(Math.random() * 10) + 1;

    const intervalId = setInterval(() => {
      if (currentIndex >= text.length) {
        clearInterval(intervalId);
        setShowCursor(false);
        return;
      }

      const nextIndex = text.indexOf(" ", currentIndex + 1);
      if (nextIndex === -1) {
        setDisplayText(text)
        setCurrentIndex(text.length);
        return;
      }

      setDisplayText(text.slice(0, nextIndex));
      setCurrentIndex(currentIndex + 1);
    }, randomInterval);
    
    return () => clearInterval(intervalId);
  }, [text, currentIndex]);

  return <span dangerouslySetInnerHTML={{__html: displayText}}></span>;
}

export default TypingEffect