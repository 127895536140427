import { FC, useState, useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Stars } from "../../assets/Icons/Stars";
import { T } from "../../styles/Theme";
import { AnswersContext } from "../../contexts/AnswerContext";
import TextValues from "../../TextValues/index.json"
import { uploadCommentRequest, uploadRateRequest } from "../../utils/requests";
import CommentBox from "../CommentBox";

const RatingStars: FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [answers, setAnswers] = useContext(AnswersContext);
  const [commentBoxActive, setCommentBoxActive] = useState<boolean>(false);

  const handleClick = async (i: any) => {
    if (!answers.rate) {
      setAnswers({ ...answers, rate: i + 1 });
      const data = {
        answer_id: answers.idAnswer,
        rate: i + 1,
      }
      uploadRateRequest(data)
        .then(res => console.log(res.status))
        .finally(() => {
          setCommentBoxActive(true);
        })
      }
      setRating(i + 1);
    }

  const handleSendComment = async () => {
    if (!!answers.comment) {
      const data = {
        answer_id: answers.idAnswer,
        comment: answers.comment,
      }
      uploadCommentRequest(data).then(res => console.log(res.status))
    }
  };
    

  if (commentBoxActive) {
    return (
      <CommentBoxContainer>
        <CommentBox
          onSubmitComment={() => handleSendComment()}
          placeholder="¡Tu feedback es importante! Cuéntanos tus impresiones..."
        />
      </CommentBoxContainer>
    );
  }

  return (
    <RatingStyled>
      <h4>{TextValues.sentences.rating}</h4>
      <div className="StarContainer">
        {[1, 2, 3, 4, 5].map((star, i) => (
          <motion.div
            key={`star-${i}`}
            whileTap={{ scale: 0.8 }}
            whileHover={{ scale: 1.2 }}
            onClick={() => handleClick(i)}
          >
            <Stars fill={rating >= i + 1 ? T.yellow : T.gray} />
          </motion.div>
        ))}
      </div>
    </RatingStyled>
  );
};

export default RatingStars;

const CommentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  max-width: 525px;

  @media (max-width: 640px) {
    padding: 0 40px;
  }
`;

const RatingStyled = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    margin: 12px 0;
    max-width: 190px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #4c4c4c;
  }

  .StarContainer {
    transform: scale(1.4);
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;

    svg {
      margin: 0 5px;
    }
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;