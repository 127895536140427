import styled from "styled-components";

export const ContainerQuestionStyled = styled.div`
  max-width: 520px;
  margin: 30px auto auto auto;
  .buttonContainer {
    margin: 35px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .alternativesContainerMulti {
    @media (min-width: 480px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
`;

export const HeaderQuestionStyled = styled.div`
  width: 100%;
  padding: 22px 10px 10px 10px;
  background: #f7f7f7;
`;

export const ClockStyled = styled.div`
  display: inline-grid;
  vertical-align: middle;
`;
