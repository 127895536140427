import { useContext } from "react";
import { Welcome } from "./Welcome";
import { Question } from "./Question";
import { EssentialsDataContext } from "../contexts/EssentialsDataContext";
import ContactScreen from "./Contact";
import { Recomendations } from "./Recommendation";

const Screens = () => {
  const [, , screenNumber] = useContext(EssentialsDataContext);

  const ScreenSelector = (key: number) => {
    switch (key) {
      case 0:
        return <Welcome />;
      case 1:
        return <Question />;
      case 2:
        return <ContactScreen />;
      case 3:
        return <Recomendations />;

      default:
        break;
    }
  };

  return <>{ScreenSelector(screenNumber)}</>;
};

export default Screens;
