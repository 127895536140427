import { useContext, useState } from "react";
import styled from "styled-components";
import { EssentialsDataContext } from "../../contexts/EssentialsDataContext";
import { AnswersContext } from "../../contexts/AnswerContext";
import { CheckBox } from "../../components/UI-KIT/CheckBox";
import { ButtonStyled } from "../../styles/GenericButton";
import { T } from "../../styles/Theme";
import { Text, Title } from "../../styles/Texts";
import TextValues from "../../TextValues/index.json";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../env";

export const ContactScreen = () => {
  const termsAndConditions = (
    <>
    <p>
    <a href="https://tryadviser.com/politica-de-privacidad.html" target="_blank" rel="noreferrer">Acepto las políticas</a>, 
      entregando consentimiento para uso de datos en campañas publicitarias y automatizaciones de {TextValues.adviserTitle.toUpperCase()} y partners asociados.
      </p>
    </>
  );

  const [, , screenNumber, setScreenNumber] = useContext(EssentialsDataContext);
  const [, setAnswers] = useContext(AnswersContext);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checked, setChecked] = useState(false);
  const [name, setName] = useState("");
  const [recaptchaVal, setRecaptchaVal] = useState(null);

  const checkEmailRegex = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  const checkPhoneRegex = (phone: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8,9}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/
    return regex.test(String(phone).toLowerCase())
  }

  const nextScreen = () => {
    setScreenNumber(screenNumber + 1);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAnswers({ email, name, phone });
    nextScreen();
  }

  return (
    <Container>
      <TitleStyled>
        {TextValues.sentences.emailTitle}
      </TitleStyled>
      <br/>
      <TextStyled>
        {TextValues.sentences.emailDescription}
      </TextStyled>
      <br />
      <form onSubmit={(e) => handleSubmit(e)}>
      <TextStyled>Nombre</TextStyled>
        <div className="inpuContainer">
          <InputContact
            placeholder="Pedro Pérez"
            autoFocus={true}
            onChange={(e) => setName(e.target.value)}
          />
        {((name !== "") && (name.length < 3)) &&  <p style={{"fontSize": 12, "color": "red"}}>Por favor ingrese un nombre válido</p>}
        </div>
      <TextStyled>Correo electrónico</TextStyled>
        <div className="inpuContainer">
          <InputContact
            placeholder="ejemplo@mail.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        {(!checkEmailRegex(email)) && (email !== "") && <p style={{"fontSize": 12, "color": "red"}}>Por favor ingrese un email válido</p>}
        </div>
        <TextStyled>Teléfono</TextStyled>
        <div className="inpuContainer">
          <InputContact
            placeholder="+5612345678"
            onChange={(e) => setPhone(e.target.value)}
          />
        {(!checkPhoneRegex(phone)) && (phone !== "") && <p style={{"fontSize": 12, "color": "red"}}>Por favor ingrese un teléfono válido</p>}
        </div>
        <div>
        <CheckBox
          id={"termsAndConditions"}
          checked={checked}
          name={"termsAndConditions"}
          text={termsAndConditions}
          tag={""}
          onClick={() => setChecked(!checked)}
        />
        </div>
        <br />
        <div className="recaptcha">
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={(val:any) => setRecaptchaVal(val)}
          />
        </div>
        <br />
        <div className="buttonContainer">
          <ButtonContactStyled
            bc={T.primary}
            c="white"
            disabled={!checkEmailRegex(email) || !checked || !checkPhoneRegex(phone) || name === "" || recaptchaVal === null}
          >
            Quiero continuar
          </ButtonContactStyled>
        </div>
        <SkipContainer>
          {/* <SkipStyled onClick={nextScreen}>Omitir</SkipStyled> */}
        </SkipContainer>
      </form>
    </Container>
  );
}

export default ContactScreen;

const TitleStyled = styled(Title)`
  margin: 30px 0 5px 0;

  @media(max-width: 480px) {
    font-weight: ${T.fontWeights.bold}
  }
`;

const TextStyled = styled(Text)`
  padding: 0 20px 0px 20px;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 520px;
  margin: auto;
  .buttonContainer {
    margin: 10px auto 5px;
    max-width: 180px;
  }
  .inpuContainer {
    padding: 0 20px 0px 20px;
    width: 100%;
  }

  .recaptcha {
    padding: 0 20px 0px 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

// InputContact centered
const InputContact = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${T.gray};
  border-radius: 5px;
  padding: 0 10px;
  font-size: ${T.fontSizes.small};
  outline: none;
  margin: 10px auto;
  &:focus {
    border: 1px solid ${T.primary};
  }
`;

const SkipStyled = styled.span`
  font-size: 12px;
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${T.primary}
  }
`;

const SkipContainer = styled.div`
  margin: 0 auto 10px;
  max-width: 30px;
  padding: 0;
  align-items: center;
}
`;

const ButtonContactStyled = styled(ButtonStyled)`
  border-radius: ${T.borderRadius.button};

  &:disabled {
    background: rgb(176, 176, 176);
  }
`;