import styled from "styled-components";
import { ButtonStyled } from "../../../styles/GenericButton";

interface ButtonProps {
  bg: string;
  c: string;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: any;
}

export const GenericButton = ({ bg, c, children, onClick, disabled }: ButtonProps) => {
  return (
    <ButtonStyled bc={bg} c={c} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonStyled>
  );
};
