export const ADVISER_IDENTIFIER = '4da780e6-9213-4751-b021-ebf421b0c504'
export const QUESTIONS_API_URL: string = 'https://d21tr6z4xhmruo.cloudfront.net'
export const SERVICES_API_URL: string = 'https://d33w76sjq8vxbi.cloudfront.net'
// export const PREDICT_API_URL: string = 'https://m9s163laej.execute-api.us-east-1.amazonaws.com/dev'
export const PREDICT_API_URL: string = 'https://clbfx4es7b.execute-api.us-east-1.amazonaws.com/dev'
export const ANSWERS_API_URL: string = "https://d1vmmc9g154f9b.cloudfront.net"
export const CONTACT_API_URL: string = "https://dia29x7l1g5qs.cloudfront.net"
export const ANALYTICS_API_URL: string = "https://b5kjzvxwik.execute-api.us-east-1.amazonaws.com/dev"
export const CHAT_RECORDS_API_URL: string = "https://ep6wdvphi5.execute-api.us-east-1.amazonaws.com/dev"
export const SENDGRID_SERVICE_URL: string = "https://u16g93pgve.execute-api.us-east-1.amazonaws.com/prod/send-recommended-notification"
export const EMAIL_TO: string = "cristian.espinosa@rindegastos.com"
export const POSITION_RIGHT_VALID_URLS = new Set([""])
export const CHAT_BETA_URL: string = "https://iknmh7qqhj.execute-api.us-east-1.amazonaws.com/dev" // new version
// export const CHAT_BETA_URL: string = "https://h85rkvygi5.execute-api.us-east-1.amazonaws.com/dev"
export const SEND_PROMPT_API: string = "https://wlebpojmpi.execute-api.us-east-1.amazonaws.com/dev" // Generic one
export const SENDGRID_RECOMMENTATIONS_URL: string = "https://u16g93pgve.execute-api.us-east-1.amazonaws.com/deploy/send-recommendations-to-user"
export const ADVISER_LOGO_URL: string = "https://tryadviser-images.s3.amazonaws.com/advisers-logos/rindegastos/logo_rindegastos.png";
export const HUBSPOT_API_URL: string = "https://c4daekizsb.execute-api.us-east-1.amazonaws.com/dev/api/v1/contact/"
export const CRM_INTERACTION_URL: string = "https://c4daekizsb.execute-api.us-east-1.amazonaws.com/dev"
// export const HUBSPOT_API_URL: string = "http://127.0.0.1/api/v1/contact/"
export const RECAPTCHA_SITE_KEY: string = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""