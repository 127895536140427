import { CheckIcon } from "../../assets/Icons/CheckIcon";
import { CircleStyled } from "../../styles/StageProgressBar";

interface ICircle {
  stage: number;
  currentStage: number;
  text: string;
  index: number;
}

export const Circle = ({ stage, currentStage, index }: ICircle) => {
  return (
    <CircleStyled>
      <div className={`circle ${stage === currentStage && "active"}`}>
        {stage === currentStage ? <CheckIcon /> : `${index}`}
      </div>
    </CircleStyled>
  );
};
