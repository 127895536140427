import styled from "styled-components";
import { T } from "../Theme";

export const Title = styled.h2`
  margin: 14px 0;
  font-weight: 500;
  color: #191919;
  font-size: ${T.fontSizes.large};
  width: 100%;
  padding: 0 20px;

  @media (min-width: 480px) {
    font-size: 24px;
  }
`;

export const Text = styled.p`
  padding: 0 20px 8px 20px;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  color: #222222;

  @media (min-width: 480px) {
    padding: 0 20px 15px 20px;
  }
`;
